import { BlockMatchError, CodeBlock } from '../utils/types';
import { processOperation } from '../handlers/operator';
import { SB3ProjectToPythonConverter } from './sb3-projectconverter';
import { BlockValue, ElemValueBaseType } from '../utils/blockvalue';
import { ValueType } from '../utils/enums';
import * as Scratch from './scratch';
import { ProcedureRegistryPayload } from '../context/procedures';

/* more information on scratch file format is ref:https://en.scratch-wiki.info/wiki/Scratch_File_Format */
export class SB3Block extends CodeBlock {
    substacks: SB3Block[][] = [];

    constructor(
        public readonly _block: Scratch.ScratchBlock,
        public readonly _id: string,
        public readonly _root: Scratch.ScratchTarget,
        public readonly converter: SB3ProjectToPythonConverter,
    ) {
        super();
        this._block = _block;
        this._id = _id;
        this._root = _root;
    }

    override get opcode() {
        return this._block.opcode;
    }

    override getSubStack(index: number): CodeBlock[] {
        return this.substacks[index];
    }

    getPeerById(blockId?: string) {
        if (!blockId) {
            return;
        }
        const rawblock = this._root?.blocks?.[blockId];
        return rawblock
            ? new SB3Block(rawblock, blockId, this._root, this.converter)
            : undefined;
    }

    getInputAsShadowId(name: string): string | undefined {
        const input = this.getInputObject(name);
        if (
            input &&
            input[0] === Scratch.ShadowState.SHADOW &&
            Array.isArray(input[1])
        ) {
            /* "inputs": {
               "BROADCAST_INPUT": [
                 1,
                 [
                   11,
                   "message1",
                   "~#$`BYkw-{5H#=8LutyJ"   // <== returns this
                 ]
               ]
             } */

            return input?.[1]?.[2]?.toString();
        } else {
            return;
        }
    }

    getFieldObject(name: string) {
        return this._block.fields[name];
    }
    getInputObject(name: string) {
        return this._block.inputs[name];
    }

    override getDescription(isPythonMode = true): string {
        const params = [];
        let procdef: ProcedureRegistryPayload | undefined = undefined;
        if (this.opcode === 'procedures_call' && !isPythonMode) {
            const proccode = this._block?.mutation?.proccode;
            procdef = this.converter.context.procedures.get(proccode);
            // const procname = ProcedureRegistryPayload.getProcName(
            //     this._block.mutation?.proccode,
            // );
            const namebv = new BlockValue(procdef?.name, { type: ValueType.STRING });
            params.push(`name: ${namebv.raw}`);
        }

        params.push(
            ...Object.entries(this._block.inputs)
                ?.filter(([k, _]) => isPythonMode || !k.startsWith('SUBSTACK'))
                ?.map(([k, _]) => {
                    let value: ElemValueBaseType;
                    let key = k;
                    try {
                        value = this.get(k, isPythonMode)?.raw;

                        if (
                            !isPythonMode &&
                            this.opcode === 'procedures_call' &&
                            procdef
                        ) {
                            const arg1 = [...procdef.args.values()].find(
                                (elem) => elem.id === k,
                            );
                            key = arg1?.name ?? k;
                        }
                    } catch {
                        value = this.getBlock(k).getDescription(isPythonMode);
                    }
                    return `${key.toLowerCase()}: ${value}`;
                }),
        );
        params.push(
            ...Object.entries(this._block.fields)?.map(
                ([k, _]) => `${k.toLowerCase()}: ${this.get(k)?.raw}`,
            ),
        );

        return `${this.opcode}(${params.join(', ')})`;
    }

    protected override _get(
        name: string | string[],
        isPythonMode = true,
        useBlockValue = true,
    ): BlockValue | SB3Block | undefined {
        if (typeof name === 'string') {
            if (useBlockValue) {
                return this._getField(name) || this._getInput(name, isPythonMode);
            } else {
                return this._getInputAsBlock(name);
            }
        } else {
            for (const i of name) {
                const value = this._get(i, useBlockValue);
                if (value) {
                    return value;
                }
            }
        }
    }

    private _getInputAsBlock(name: string): SB3Block | undefined {
        const input = this._block.inputs[name];
        if (!input) {
            return;
        }

        return this.getPeerById(input[1] as string);
    }

    private _getInput(name: string, isPythonMode = true): BlockValue | undefined {
        const input = this._block.inputs[name];
        if (!input) {
            return;
        }

        switch (input[0]) {
            case Scratch.ShadowState.SHADOW:
                {
                    const is_reference = typeof input[1] === 'string';
                    const is_direct_value = Array.isArray(input[1]);
                    if (is_reference) {
                        const block2 = this.getPeerById(input[1] as string);
                        if (!block2 || typeof block2 !== 'object') {
                            return;
                        }

                        if (block2.opcode === 'procedures_prototype') {
                            return new BlockValue(block2._block.mutation?.proccode);
                        }

                        const first_field = Object.values(block2?._block.fields)[0];
                        const first_field_value = first_field[0];
                        return first_field_value !== undefined
                            ? new BlockValue(first_field_value, {
                                  type:
                                      typeof first_field_value !== 'number'
                                          ? ValueType.STRING
                                          : ValueType.NUMBER,
                              })
                            : undefined;
                    } else if (is_direct_value) {
                        const value_array = input[1] as Scratch.BlockValueArray;
                        if (value_array === undefined) {
                            return;
                        }

                        const value_type =
                            value_array[0]; /* 4 = value, 5 = wait-duration-sec, 6 = times, 10 = string, 11 = message (name, ref) */
                        const is_string =
                            value_type === Scratch.BlockValueType.STRING ||
                            value_type === Scratch.BlockValueType.BROADCAST;
                        const value_value =
                            value_type === Scratch.BlockValueType.STRING ||
                            value_type === Scratch.BlockValueType.BROADCAST
                                ? value_array[1].toString()
                                : parseFloat(value_array[1].toString());
                        return new BlockValue(value_value, {
                            type: is_string ? ValueType.STRING : ValueType.NUMBER,
                        });
                    }
                }
                break;
            case Scratch.ShadowState.NOSHADOW:
                {
                    throw new BlockMatchError(
                        'Input is a blocks, use get_inputAsBlock',
                    );
                }
                break;
            case Scratch.ShadowState.OBSCURED:
                {
                    const ref = input[1];
                    if (typeof ref === 'string') {
                        const block2 = this.getPeerById(ref.toString());
                        if (!block2) {
                            return;
                        }

                        const op = isPythonMode
                            ? processOperation.call(this.converter, block2)
                            : new BlockValue(block2.getDescription(isPythonMode));
                        return op;
                    } else if (typeof ref === 'object' && Array.isArray(ref)) {
                        /* assert(ref[0] === 12 || ref[0] === 13); */
                        const var_entry = this.converter.context.variables.get([
                            ref[1].toString(),
                            ref[0] === Scratch.BlockValueType.LIST,
                        ]);
                        return new BlockValue(var_entry?.py, {
                            is_dynamic: true,
                            is_variable: true,
                        });
                    }
                }
                break;
        }
    }

    private _getField(name: string) {
        const field = this.getFieldObject(name);
        return field
            ? new BlockValue(field[0], {
                  type:
                      typeof field[0] !== 'number'
                          ? ValueType.STRING
                          : ValueType.NUMBER,
              })
            : undefined;
    }

    static buildStack(block: SB3Block): SB3Block[] {
        const retval: SB3Block[] = [];

        while (block) {
            retval.push(block);

            const processSubstackByInput = (block: SB3Block, name: string) => {
                if (!block._block.inputs || !Object.hasOwn(block._block.inputs, name)) {
                    return;
                }

                const substack_id = block._block.inputs[name]?.[1];
                if (!substack_id || typeof substack_id !== 'string') {
                    return;
                }
                const substackBlock = block.getPeerById(substack_id);
                if (substackBlock) {
                    block.substacks.push(this.buildStack(substackBlock));
                }
            };
            processSubstackByInput(block, 'SUBSTACK');
            processSubstackByInput(block, 'SUBSTACK2');

            /* if there is no next - use undefined instead of null */
            const nextBlock = block.getPeerById(block._block.next ?? undefined);
            if (!nextBlock) {
                break;
            }
            block = nextBlock;
        }

        return retval;
    }
}
