import {
    BlockField,
    ProjectInfo,
    ScratchBlock,
    ScratchProject,
    ScratchTarget,
} from './scratch';
import { INDENT, getFileExtension, get_divider, indent_code } from '../utils/utils';
import PyConverterOptions, { PyProjectResult } from '../pyconverteroptions';
import { buildPyCode, generatePyFunctionHead } from '../projectconverter';

import { CodeConverter } from '../utils/types';
import { Context } from '../context/context';
import { DeviceType } from '../utils/enums';
import JSZip from 'jszip';
import { ProcedureRegistryPayload } from '../context/procedures';
import { SB3Block } from './block';
import { VariableRegistryPayload } from '../context/variables';
import { handleBlocks } from '../handlers/handlers';
import { initMotorPairMovementPair } from '../handlers/motorpair';
import { processOperation } from '../handlers/operator';

enum StackGroupType {
    Start = 1,
    Event = 2,
    MessageEvent = 3,
    MyBlock = 4,
    Orphan = 9,
}

interface StackGroup {
    groupid?: number;
    opcode: string;
    shortname: string;
    group: StackGroupType;
    stack: SB3Block[];
    writeAccessVariables: VariableRegistryPayload[];
}

type OutputCodeStack = {
    id: string | undefined;
    code: string[] | undefined;
    isStartup?: boolean;
    isDivider?: boolean;
    name?: string;
    group?: StackGroupType;
};

/* LEGO SPIKE v2, v3, LEGO Robot Inventor Mindstorms format */
export const SPIKE_BLOCKLY_EXTENSIONS: string[] = ['.llsp3', '.llsp'] as const;
export const ROBOTINVENTOR_BLOCKLY_EXTENSIONS: string[] = ['.lms'] as const;
/* LEGO EV3 Classroom */
export const EV3_BLOCKLY_EXTENSIONS: string[] = ['.lmsp'] as const;

export class SB3ProjectToPythonConverter implements CodeConverter {
    _options: PyConverterOptions;

    constructor(options: PyConverterOptions, readonly context = new Context()) {
        this._options = options;

        const extension = getFileExtension(this._options.filename);
        if (SPIKE_BLOCKLY_EXTENSIONS.includes(extension)) {
            this.context.deviceType = DeviceType.SPIKE;
        } else if (ROBOTINVENTOR_BLOCKLY_EXTENSIONS.includes(extension)) {
            this.context.deviceType = DeviceType.ROBOTINVENTOR;
        } else if (EV3_BLOCKLY_EXTENSIONS.includes(extension)) {
            this.context.deviceType = DeviceType.EV3CLASSROOM;
        } else {
            throw new Error('Unknown device type');
        }
    }

    public static supportsExtension(ext: string): boolean {
        return (
            SPIKE_BLOCKLY_EXTENSIONS.includes(ext) ||
            ROBOTINVENTOR_BLOCKLY_EXTENSIONS.includes(ext) ||
            EV3_BLOCKLY_EXTENSIONS.includes(ext)
        );
    }

    public async convert(
        filedata: ArrayBuffer | Buffer,
        options: PyConverterOptions,
    ): Promise<PyProjectResult> {
        const retval: PyProjectResult = {};
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(filedata);
        if (!zipContent) {
            throw new Error('Error loading the zip file.');
        }

        // ========================
        const manifestFile = zipContent.file('manifest.json');
        if (!manifestFile) {
            throw new Error('No manifest.json file found in the zip file.');
        }
        const manifestContent = await manifestFile.async('string');
        const projectInfo = JSON.parse(manifestContent);
        if (options?.debug?.callback)
            options.debug.callback(
                'projectInfo.json',
                JSON.stringify(projectInfo, null, 2),
            );

        if (
            projectInfo.type &&
            !['word-blocks', 'icon-blocks'].includes(projectInfo.type)
        ) {
            throw new Error(
                `File type should be word-blocks instead of "${projectInfo.type}"`,
            );
        }
        const projectComment = !options?.debug?.skipHeader
            ? this.extractHeadComment(projectInfo)
            : null;

        // ========================
        {
            const filename = 'icon.svg';
            const file = zipContent.file('icon.svg');
            if (!file) {
                throw new Error(`No ${filename} file found in the zip file.`);
            }
            const svgContent = await file.async('string');
            retval.additionalFields = {
                blockly: {
                    slot: projectInfo.slotIndex,
                    svg: svgContent,
                },
            };
        }

        // ========================
        const scratch_file = await zipContent.file('scratch.sb3');
        if (!scratch_file) {
            throw new Error('Missing scratch.sb3');
        }
        const scratch_data = await scratch_file?.async('arraybuffer');
        const sb3zip = await zip.loadAsync(scratch_data);
        const projectFile = sb3zip.file('project.json');
        if (!projectFile) {
            throw new Error('Missing project.json');
        }
        const projectData = await projectFile.async('text');
        const projectJson = JSON.parse(projectData);
        if (options?.debug?.callback)
            options.debug.callback(
                'projectJson.json',
                JSON.stringify(projectJson, null, 2),
            );

        // ========================
        const codes = this.convertProject(projectJson);
        if (codes) {
            const sections = [projectComment, codes.pycode].filter((elem) => elem);
            retval.pycode = sections.join('\n');
            retval.plaincode = codes.plaincode;
        }

        retval.deviceType = this.context?.deviceType;
        return retval;
    }

    private extractHeadComment(projectInfo: ProjectInfo) {
        return `
"""
Project:    ${projectInfo.name}
Slot:       ${projectInfo.slotIndex}
Created:    ${projectInfo.created}
Last saved: ${projectInfo.lastsaved}

"""
`.trim();
    }

    private convertProject(projectData: ScratchProject) {
        // TODO: CHECK: context.reset()
        if (!projectData.targets || projectData.targets.length < 2) {
            return;
        }

        this.preprocessMessages(projectData.targets[0]);
        const retval = this.convertMainTarget(projectData.targets[1]);
        return retval;
    }

    private convertMainTarget(target1: ScratchTarget) {
        // ========================
        let plaincode, pycode;
        try {
            this.preprocessProcedureDefinitions(target1);
            this.preprocessVariables(target1);

            // ------------------------
            const topLevelStacks = this.prepareTopLevelStacks(target1);

            // ------------------------
            plaincode = this.generatePlainCodeForStacks(topLevelStacks);

            // ------------------------
            const stackGroups = this.getStackGroups(topLevelStacks);
            this.preprocessStackGroups(stackGroups);

            // switch to async if there ar multiple start stacks or any event stack
            if (!this.context.isAsyncNeeded) {
                this.context.isAsyncNeeded =
                    (stackGroups.get(StackGroupType.Start)?.length ?? 0) > 1 ||
                    (stackGroups.get(StackGroupType.Event)?.length ?? 0) > 0 ||
                    (stackGroups.get(StackGroupType.MessageEvent)?.length ?? 0) > 0 ||
                    false;
            }

            const programStacks = this.getPycodeForStackGroups(stackGroups);
            const programCode = this.createProgramStacksCode(programStacks) ?? [];
            const mainProgramCode = this.createMainProgramCode(programStacks);

            pycode = buildPyCode.call(
                this,
                this._options,
                programCode,
                mainProgramCode,
            );
        } catch (err) {
            console.error('::ERROR::', err);
        }

        return {
            pycode,
            plaincode,
            deviceType: this.context.deviceType,
        };
    }

    private preprocessVariables(target1: ScratchTarget) {
        if (!target1.variables) {
            return;
        }
        if (!target1.lists) {
            return;
        }

        for (const varblock of Object.values(target1.variables)) {
            if (Array.isArray(varblock)) {
                const name = varblock[0];
                // respect the non-list type to avoid collision
                this.context.variables.use([name, false], null, false);
            }
        }
        for (const varblock of Object.values(target1.lists)) {
            if (Array.isArray(varblock)) {
                const name = varblock[0];
                // respect the list type to avoid collision
                this.context.variables.use([name, true], null, true);
            }
        }
    }

    private generatePlainCodeForStacks(topLevelStacks: SB3Block[][]) {
        const genSimpleCodeForStack = (
            blocks: SB3Block[],
            doIndentFirst = true,
        ): string[] => {
            return blocks
                ?.map((block, index) => {
                    const code: string[] = [
                        (!doIndentFirst || index > 0 ? INDENT : '') +
                            block.getDescription(false),
                    ];
                    block.substacks?.map((substack, substackindex) => {
                        const substackCode = genSimpleCodeForStack(
                            substack,
                            false,
                        )?.map((line) => INDENT + line);
                        if (substackindex > 0) {
                            code.push(INDENT + '└──');
                        }
                        if (substackCode) {
                            code.push(...substackCode);
                        }
                    });

                    return code;
                })
                .flat();
        };
        const genSimpleCodeForStacks = (stacks: SB3Block[][]) => {
            return stacks
                .map((stack) => genSimpleCodeForStack(stack, true))
                .map((slines) => [...slines, ''])
                .flat();
        };

        const code = genSimpleCodeForStacks(topLevelStacks);
        return code.join('\n');
    }

    private preprocessMessages(target0: ScratchTarget) {
        if (!target0.broadcasts) {
            return;
        }

        Object.entries(target0.broadcasts).forEach(([id, name]) =>
            this.context.broadcasts.use(id, name),
        );
    }

    private preprocessProcedureDefinitions(target1: ScratchTarget) {
        if (!target1.blocks) {
            return;
        }

        Object.entries(target1.blocks)
            .filter(([_, sblock]) => sblock.opcode === 'procedures_definition')
            .forEach(([id, sblock]) => {
                const block = new SB3Block(sblock, id, target1, this);
                const procdef = ProcedureRegistryPayload.create(block);
                if (procdef) {
                    this.context.procedures.use(procdef.id, procdef);
                }
            });
    }

    private createProgramStacksCode(programStacks: OutputCodeStack[]): string[] {
        const stacks = Array.from(programStacks.values()).filter(
            (ostack) => ostack.code,
        );
        return stacks?.length > 0
            ? stacks
                  .filter(
                      (ostack) =>
                          !this._options?.debug?.showThisStackOnly ||
                          ostack.id === this._options?.debug?.showThisStackOnly,
                      // && (this._options?.debug?.showOrphanCode || ostack.group !== StackGroupType.Orphan)
                  )
                  .map((ostack) =>
                      ostack.code && ostack.code.length > 0 && !ostack.isDivider
                          ? [
                                this._options.debug?.showBlockIds
                                    ? `# BlockId: "${ostack.id}"`
                                    : null,
                                ...ostack.code,
                                '',
                            ]
                          : ostack.code
                          ? // divider or empty
                            [...ostack.code]
                          : [],
                  )
                  .flat()
                  .filter((line) => line !== undefined && line !== null)
            : [];
    }

    private createMainProgramCode(ostacks: OutputCodeStack[]) {
        const startupStacks = ostacks.filter((ostack) => ostack.isStartup);

        if (ostacks.length === 0 || startupStacks.length === 0) {
            return [
                '# no startup stacks registered, program will not do anything',
                this.context.isAsyncNeeded ? 'yield' : 'pass',
            ];
        }

        if (this.context.isAsyncNeeded) {
            this.context.imports.use('pybricks.tools', 'multitask, run_task');

            // multiple start stacks
            return [
                'async def main():',
                indent_code([
                    `await multitask(${[
                        ...startupStacks.map((ostack) => `${ostack.name}()`),
                    ].join(', ')})`,
                ])[0],
                'run_task(main())',
            ];
        } else {
            // single start stack
            return [`${startupStacks[0].name}()`];
        }
    }

    private getPycodeForStackGroups(
        stackGroups: Map<StackGroupType, StackGroup[]>,
    ): OutputCodeStack[] {
        const aggregatedCodeStacks: OutputCodeStack[] = [];

        for (const [group_name, stack_group] of stackGroups.entries()) {
            if (
                group_name === StackGroupType.Orphan &&
                !this._options?.debug?.showOrphanCode
            ) {
                continue;
            }

            // add a header code
            const groupNameStr = StackGroupType[group_name];
            aggregatedCodeStacks.push({
                id: undefined,
                code: [get_divider(`GROUP: ${groupNameStr.toUpperCase()}`, '', '-')],
                isStartup: false,
                isDivider: true,
                group: group_name,
            });

            let lastStackEventMessageId: string | undefined = undefined;
            const aggregatedMessageFns: string[] = [];
            for (const stack_gitem of stack_group) {
                try {
                    const code: string[] = [];
                    const currentStack = stack_gitem.stack;
                    const group = stack_gitem.group;
                    const headBlock = currentStack[0];
                    const nextBlocks = currentStack.slice(1);

                    let stack_fn = `stack${stack_gitem.groupid}_${stack_gitem.shortname}_fn`;
                    const stackActionFn = `stack${stack_gitem.groupid}_action_fn`;
                    let description = headBlock.getDescription();
                    let funcSignature = `${stack_fn}()`;

                    const messageRecord = this.getMessageRecord(currentStack);
                    const messageId = messageRecord?.[1];
                    const messageNameRaw = messageRecord?.[0]?.toString();
                    // const messageName = sanitize(messageNameRaw);
                    const isMessageChanged = lastStackEventMessageId !== messageId;
                    lastStackEventMessageId = this.checkAndRegisterMessage(
                        currentStack,
                        stackActionFn,
                        lastStackEventMessageId,
                        aggregatedCodeStacks,
                        aggregatedMessageFns,
                        false,
                    );

                    if (group === StackGroupType.MyBlock) {
                        const functionDef = ProcedureRegistryPayload.create(
                            headBlock,
                            true,
                        );
                        //procedures.use(functionDef.id, functionDef);
                        // Procedures.register(functionDef);

                        if (!functionDef) {
                            return [];
                        }
                        stack_fn = functionDef.getPyName('myblock_');
                        funcSignature = functionDef.getPyDefinition();
                        description = funcSignature;
                    } else if (group === StackGroupType.MessageEvent) {
                        if (isMessageChanged) {
                            aggregatedCodeStacks.push({
                                id: undefined,
                                code: [
                                    get_divider(`MESSAGE: ${messageNameRaw}`, '', '-'),
                                ],
                                isStartup: false,
                                isDivider: true,
                                group,
                            });
                        }
                    }

                    code.push(`# STACK #${stack_gitem.groupid}: ${description}`);
                    const comment = this.getCommentForBlock(headBlock);
                    if (comment) {
                        code.push(`# ${comment}`);
                    }

                    const sub_code = this.process_stack(nextBlocks);
                    switch (group_name) {
                        case StackGroupType.Start:
                        case StackGroupType.MyBlock:
                            {
                                const headCode = generatePyFunctionHead.call(
                                    this,
                                    funcSignature,
                                    currentStack,
                                );
                                code.push(...headCode);

                                code.push(...sub_code);

                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: group_name === StackGroupType.Start,
                                    name: stack_fn,
                                    isDivider: false,
                                    group,
                                });
                            }
                            break;
                        case StackGroupType.Event:
                            {
                                // case 'flipperevents_whenButton': // TODO: later separate and optimize
                                // case 'flipperevents_whenTimer': // TODO: later separate and optimize

                                // stack action function
                                code.push(`async def ${stackActionFn}():`);
                                code.push(...sub_code);

                                // condition function
                                const stack_cond_fn = `stack${stack_gitem.groupid}_condition_fn`;
                                const condition_code = processOperation.call(
                                    this,
                                    headBlock,
                                );
                                code.push(`async def ${stack_cond_fn}():`);
                                code.push(
                                    ...indent_code(['return ' + condition_code.raw]),
                                );
                                code.push(`async def ${stack_fn}():`);
                                code.push(
                                    ...indent_code([
                                        `await ${
                                            this.context.helpers
                                                .use('event_task')
                                                ?.call(stack_cond_fn, stackActionFn).raw
                                        }`,
                                    ]),
                                );

                                // add to stack
                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: true,
                                    name: stack_fn,
                                    group,
                                });
                            }
                            break;

                        case StackGroupType.MessageEvent:
                            {
                                // const messageName = getMessageName(currentStack);

                                // stack action function
                                code.push(`async def ${stackActionFn}():`);
                                code.push(...sub_code);

                                // condition function already added once on top

                                // add to stack
                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: false,
                                    group,
                                });
                            }
                            break;

                        default:
                            {
                                if (!this._options?.debug?.showOrphanCode) {
                                    continue;
                                }

                                code.push(
                                    '### this code has no hat block and will not be running',
                                );
                                const sub_code = this.process_stack(currentStack).map(
                                    (line) => '# ' + line,
                                );
                                code.push(...sub_code);

                                // add to stack, but not to startup
                                aggregatedCodeStacks.push({
                                    id: headBlock._id,
                                    code: code,
                                    isStartup: false,
                                    group,
                                });
                            }
                            break;
                    }
                } catch (err) {
                    console.error('::ERROR::', err);
                }
            }

            // dump any potential accumulated message
            this.checkAndRegisterMessage(
                undefined,
                undefined,
                lastStackEventMessageId,
                aggregatedCodeStacks,
                aggregatedMessageFns,
                true,
            );
        }

        return aggregatedCodeStacks;
    }

    private checkAndRegisterMessage(
        currentStack: SB3Block[] | undefined,
        stackActionFn: string | undefined,
        lastStackEventMessageId: string | undefined,
        aggregatedCodeStacks: OutputCodeStack[] | undefined,
        aggregatedMessageFns: string[],
        forceDump: boolean,
    ) {
        if (!aggregatedCodeStacks) {
            return;
        }

        const messageRecord = currentStack
            ? this.getMessageRecord(currentStack)
            : undefined;
        const messageId = messageRecord?.[1] ?? undefined;
        if (
            aggregatedMessageFns.length &&
            (lastStackEventMessageId !== messageId || forceDump)
        ) {
            const bco = this.context.broadcasts.get(lastStackEventMessageId);

            this.context.helpers.use('class_Message');
            const message_fn = bco?.get_pyname();
            aggregatedCodeStacks.push({
                id: message_fn,
                code: bco ? [bco.get_code(aggregatedMessageFns)] : [],
                isStartup: false,
            });
            const stack_fn = `${message_fn}.main_fn`;
            aggregatedCodeStacks.push({
                id: undefined,
                code: undefined,
                isStartup: true,
                name: stack_fn,
            });

            aggregatedMessageFns.splice(0, aggregatedMessageFns.length); // empty aggregatedMessageFns
            lastStackEventMessageId = messageId;
        }

        if (messageId?.length) {
            if (!this.context.broadcasts.has(messageId)) {
                const messageName = messageRecord?.[0]?.toString();
                this.context.broadcasts.use(messageId, messageName);
            }
            if (stackActionFn) {
                aggregatedMessageFns.push(stackActionFn);
            }
            lastStackEventMessageId = messageId;
        }

        return lastStackEventMessageId;
    }

    prepareTopLevelStacks(target1: ScratchTarget): SB3Block[][] {
        if (!target1.blocks) {
            return [];
        }
        return Object.entries(target1.blocks)
            .filter(([, sblock]) => sblock.topLevel && !sblock.shadow)
            .sort((a, b) => {
                return (
                    Math.floor((a[1]?.y ?? 0) / 600) -
                        Math.floor((b[1].y ?? 0) / 600) ||
                    Math.floor((a[1]?.x ?? 0) / 400) - Math.floor((b[1].x ?? 0) / 400)
                );
            })
            .map(([id, sblock]) => {
                return this.prepareStack(sblock, id, target1);
            });
    }

    public prepareStack(
        sblock: ScratchBlock,
        id: string,
        root: ScratchTarget,
    ): SB3Block[] {
        const block = new SB3Block(sblock, id, root, this);
        return SB3Block.buildStack(block);
    }

    private getStackGroups(topLevelStacks: SB3Block[][]) {
        const retval = topLevelStacks
            .map((stack) => {
                const headBlock = stack[0];
                const op = headBlock.opcode;
                function get_op_group(op: string): [StackGroupType, string | null] {
                    let m: RegExpExecArray | null;

                    const PATTERNS: { pattern: RegExp; type: StackGroupType }[] = [
                        {
                            pattern:
                                /^(?:flipper|horizontal|ev3)events_(whenProgramStarts)/,
                            type: StackGroupType.Start,
                        },
                        {
                            pattern:
                                /^event_when(broadcast)received|horizontalevents_when(Broadcast)/,
                            type: StackGroupType.MessageEvent,
                        },
                        {
                            pattern: /^(?:flipper|horizontal|ev3)events_(when.*)/,
                            type: StackGroupType.Event,
                        },
                        // Uncomment this section if you decide to use it later
                        // { pattern: /^radiobroadcast_(whenIReceiveRadioSignal)Hat/, type: StackGroupType.Event },
                        {
                            pattern: /^(procedures_definition)/,
                            type: StackGroupType.MyBlock,
                        },
                    ];

                    for (const { pattern, type } of PATTERNS) {
                        m = pattern.exec(op);
                        if (m) {
                            return [type, m[1]];
                        }
                    }

                    // Default case
                    return [StackGroupType.Orphan, null];
                }
                const [group, shortname] = get_op_group(op);
                return {
                    opcode: op,
                    shortname: shortname?.toString().toLowerCase(),
                    group,
                    stack: stack,
                    writeAccessVariables: [],
                } as StackGroup;
            })
            .sort((a, b) => a.group.valueOf() - b.group.valueOf())
            .reduce((output, item) => {
                const key = item['group'];
                const values = output.get(key) || [];
                //if (!output.has(key)) output.set(key, []);
                values.push(item);
                output.set(key, values);
                return output;
            }, new Map<StackGroupType, StackGroup[]>());

        if (retval.get(StackGroupType.MessageEvent)) {
            retval.get(StackGroupType.MessageEvent)?.sort(
                (a: StackGroup, b: StackGroup) =>
                    this.getMessageRecord(a?.stack)?.[0]
                        ?.toString()
                        ?.localeCompare(
                            this.getMessageRecord(b?.stack)?.[0]?.toString() ?? '',
                        ) ?? 0,
            );
        }

        return retval;
    }

    private preprocessStackGroups(stackGroups: Map<StackGroupType, StackGroup[]>) {
        let stackCounter = 0;
        let motorpairFound = false;
        for (const [stack_type, stack_group] of stackGroups.entries()) {
            if (stack_type === StackGroupType.Orphan) {
                continue;
            }
            // TODO: perform a recursive call
            for (const stack of stack_group) {
                stack.groupid = ++stackCounter;
                for (const block of stack.stack) {
                    const op = block.opcode;
                    if (
                        op.match(/(flippermove|ev3move)_setMovementPair/) &&
                        !motorpairFound
                    ) {
                        initMotorPairMovementPair
                            .call(this, block, undefined, true)
                            .ensureDependencies();
                        motorpairFound = true;
                        // only one/first setMovementPair is taken into account
                    } else if (op === 'flippercontrol_fork') {
                        this.context.isAsyncNeeded = true;
                    }
                }
            }
        }

        // only if no setMovementPair is used
        if (!motorpairFound) {
            initMotorPairMovementPair
                .call(this, undefined, ['A', 'B'], false)
                .ensureDependencies();
        }
    }

    public process_stack(blocks: SB3Block[] | null): string[] {
        const retval: string[] = [];

        if (blocks && blocks.length > 0) {
            for (const block of blocks) {
                const comment = this.getCommentForBlock(block);
                if (comment) {
                    retval.push(...indent_code(`# ${comment}`));
                }

                const sub_code = this.convertBlockToCode(block);
                if (sub_code !== null) {
                    retval.push(...indent_code(sub_code));
                }
            }
        } else {
            retval.push(...indent_code(this.context.isAsyncNeeded ? 'yield' : 'pass'));
        }

        return retval;
    }

    private getMessageRecord(stack: SB3Block[]): BlockField | undefined {
        const headBlock = stack?.[0];
        if (headBlock?.opcode === 'event_whenbroadcastreceived') {
            // [0] is the message name, [1] is the message refid
            return headBlock.getFieldObject('BROADCAST_OPTION');
        } else if (headBlock?.opcode === 'horizontalevents_whenBroadcast') {
            // "CHOICE": [
            //   1,
            //   "zFLqW+b*f2b]lG6nUD/."
            // ]
            const eventcolor = headBlock.get('CHOICE')?.toString();
            // let the eventcolor be the message id as well
            return [eventcolor, eventcolor];
        } else {
            return;
        }
    }

    private getCommentForBlock(block: SB3Block) {
        const comment = block._block.comment
            ? block._root.comments?.[block._block.comment]?.text
            : undefined;
        return comment?.replace(/[\r\n]/g, ' ');
    }

    public convertBlockToCode(block: SB3Block): string[] | null {
        try {
            const retval = handleBlocks.call(this, block);
            if (retval) {
                if (this._options.debug?.showExplainingComments) {
                    const pseudoLine = block.getDescription(false);
                    retval.unshift(...['', `# ${pseudoLine}`]);
                }
                return retval;
            }
        } catch (e) {
            console.trace(e);
            return [`# error with: ${block.getDescription()} @ ${block._id} - ${e}`];
        }

        // _debug('unknown block', block.get_block_description());
        return [`# Unknown: ${block.getDescription()}`, 'pass'];
    }
}
